import React, { useEffect, useRef, useState, useCallback, memo } from 'react';
import { createPortal } from 'react-dom';
import { TooltipProps } from './ToolTip.types';


const Tooltip: React.FC<TooltipProps> = ({
  text,
  position = 'top',
  className = '',
  style = {},
  children,
  childRef,
}) => {
  const wrapperRef = useRef<HTMLDivElement>(null); // Fallback wrapper ref
  const tooltipRef = useRef<HTMLDivElement>(null);
  const [styles, setStyles] = useState<React.CSSProperties>({});
  const [isVisible, setIsVisible] = useState(false);

  // Memoize the calculateStyles function to prevent unnecessary re-renders
  const calculateStyles = useCallback(() => {
    const targetRef = childRef ?? wrapperRef; // Use childRef if provided, else fallback to wrapperRef

    if (targetRef.current && tooltipRef.current) {
      const targetRect = targetRef.current.getBoundingClientRect();
      const tooltipRect = tooltipRef.current.getBoundingClientRect();

      const newStyles: React.CSSProperties = {
        top: targetRect.top + window.scrollY || 0,
        left: targetRect.left + window.scrollX || 0,
        ...style, // Apply custom style prop here
      };

      let widthDiff = targetRect.width - tooltipRect.width;
      widthDiff = widthDiff<0?0:widthDiff

      switch (position) {
        case 'top':
          (newStyles.top as number) -= (tooltipRect.height + 8) || 0;
          (newStyles.left as number) += (widthDiff / 2) || 0;
          break;
        case 'bottom':
          (newStyles.top as number) += (targetRect.height + 8) || 0;
          (newStyles.left as number) += (widthDiff / 2) || 0;
          break;
        case 'left':
          (newStyles.top as number) += ((targetRect.height - tooltipRect.height) / 2) || 0;
          (newStyles.left as number) -= (tooltipRect.width + 8) || 0;
          break;
        case 'right':
          (newStyles.top as number) += ((targetRect.height - tooltipRect.height) / 2) || 0;
          (newStyles.left as number) += (targetRect.width + 8) || 0;
          break;
        default:
          break;
      }

      setStyles(newStyles);
    }
  }, [childRef, position, style]);

  // Recalculate tooltip position only when visibility changes
  useEffect(() => {
    if (isVisible) {
      calculateStyles();
    }
  }, [isVisible, calculateStyles]);

  // Show or hide tooltip on hover when using childRef
  useEffect(() => {
    if (childRef?.current) {
      const handleMouseEnter = () => setIsVisible(true);
      const handleMouseLeave = () => setIsVisible(false);

      childRef.current.addEventListener('mouseenter', handleMouseEnter);
      childRef.current.addEventListener('mouseleave', handleMouseLeave);

      return () => {
        childRef.current?.removeEventListener('mouseenter', handleMouseEnter);
        childRef.current?.removeEventListener('mouseleave', handleMouseLeave);
      };
    }
  }, [childRef]);

  if (childRef) {
    return createPortal(
      <div
        ref={tooltipRef}
        style={{ position: 'absolute', ...styles }}
        className={`whitespace-nowrap bg-gray-800 p-2 rounded transition-opacity duration-200 z-100 rounded-md bg-white shadow text-sm text-neutral-black ${className}`}
      >
        {text}
      </div>,
      document.body
    );
  }

  // If no childRef, use children as a wrapper
  return (
    <div
      ref={wrapperRef} // Use wrapperRef only if childRef is not provided
      onMouseEnter={() => setIsVisible(true)}
      onMouseLeave={() => setIsVisible(false)}
      className="inline-block"
    >
      {children}
      {isVisible &&
        createPortal(
          <div
            ref={tooltipRef}
            style={{ position: 'absolute', ...styles }}
            className={`whitespace-nowrap bg-gray-800 p-2 rounded transition-opacity duration-200 z-100 rounded-md bg-white shadow text-sm text-neutral-black ${className}`}
          >
            {text}
          </div>,
          document.body
        )}
    </div>
  );
};

export default memo(Tooltip);
